import React from 'react';
import { Link } from 'react-router-dom';
import QuickContact from './QuickContact';

function Programs() {
  return (
  <div>
      <div className='banner'>PROGRAMS</div>
      <div className='programs'>
     <div className='q-contact'>
     <div className='program-info'>
      <div className='program-item box-shadow'>
        <h1 className='purple'>We are here to serve you</h1>
        <p>For a full list of the medications that we provide, please visit the Medications page click <Link to="/services/medications" className='purple'>HERE</Link></p>
          <ul>
            <li className='square'><span className='green'>21-day Detox (Short Term): </span>The patient will be stabilized and tapered off their prescribed medication within a 21-day period. Traditionally this program has been most effective when utilizing methadone as the medication provided.</li>
            <li className='square'><span className='green'>180-day Detox (Long Term):</span> The patient will be stabilized on their prescribed medication and slowly tapered off over a 6 month period. The patient may complete this program prior to 180 days when clinically appropriate.</li>
            <li className='square'> <span className='green'>Maintenance:</span> The patient will be stabilized on their selected/appropriate medication and the dose will be maintained throughout the course of treatment. The length of treatment will be determined by the physician and patient, as this program offers ongoing treatment without a specific end date. Pregnant patients are included under this program.</li>
          </ul>
          <div>
      <h3 className='purple'>What We Offer</h3>
      <ul>
        <li className='square'>Counseling</li>
        <li className='square'>Case Management</li>
        <li className='square'>Crisis Intervention</li>
        <li className='square'>Drug Education</li>
        <li className='square'>Patient Advocacy</li>
        <li className='square'>Urine Tests</li>
      </ul>
    </div>
    <div>
      <h3 className='purple'>What Our Treatment Protocol Consists Of</h3>
      <ul>
        <li className='square'>Screening</li>
        <li className='square'>Intake</li>
        <li className='square'>Orientation</li>
        <li className='square'>Assessment</li>
        <li className='square'>Treatment Planning</li>
        <li className='square'>Counseling</li>
        <li className='square'>Case Management</li>
        <li className='square'>Crisis Intervention</li>
        <li className='square'>Patient/Client Education</li>
        <li className='square'>Referral</li>
        <li className='square'>Report & Record Keeping</li>
        <li className='square'>Consultation with other professionals in regard to client treatment</li>
      </ul>
    </div>
          <div>
          <p>Initially, the patient must visit the clinic regularly and receive his/her medication, yet over time the successful patient earns privileges.  These can include fewer clinic visits and eligibility for take home medications as allowed by state regulation.</p>
          <p>SOAP MAT focuses on breaking the destructive cycle of opioid use disorder and dependency, understands the difficulties of the social stigma associated with experiencing substance use disorders and believes an independent and constructive lifestyle is attainable. SOAP staff members believe that all patients should be treated with dignity and respect and are committed to delivering quality healthcare services in a compassionate and supportive environment.</p>
          <p>Medications developed to treat opioid use disorder work through the same receptors as opioids, but are safer and less likely to produce the harmful behaviors that characterize substance use disorders. Physicians prescribe a particular medication based on a patient’s specific medical needs to help reduce withdrawal symptoms and cravings.  Methadone and buprenorphine are medications used to treat opioid dependence and misuse. People may safely take medications used in MAT for months, years, several years, or even a lifetime.</p>
          <p>Taking medication for opioid use disorder is like taking medication to control heart disease or diabetes. It is not the same as substituting one drug for another. The prescribed medication operates to normalize brain chemistry, block the euphoric effects of opioids, relieve physiological cravings, and normalize body functions without the negative effects of the abused drug. If controlled and used properly, the medication does not create a new misuse but helps people manage substance use disorder symptoms so that the benefits of recovery can be maintained. In turn, patients are better able to focus on the lifestyle changes that lead back to healthy living.</p>
        </div>

        <div>
          <h1 className='purple'>Jail Dosing</h1>
            <p>Whenever possible, SOAP MAT counselors cooperate with local jails to encourage continuity of treatment for incarcerated maintenance treatment patients. If an agreement is in place with the jail, for an additional fee a detoxification regimen can be implemented with the patient upon notification of the patient’s incarceration.</p>
            <p>Due to the numerous restrictions that are in place at the detention facilities, we cannot guarantee we will gain access to medicate patients while they are incarcerated, and as noted above, there is an additional fee for this service.</p>
        </div>

        <div>
        <h1 className='purple'>The Treatment Solution</h1>
          <p>Overall, treatment of substance use disorders is as successful as treatment of other chronic diseases, such as diabetes, hypertension, and asthma.</p>
          <p>Methadone is one of the longest-established, most thoroughly evaluated forms of drug treatment. The science is overwhelming in its findings about methadone treatment’s effectiveness. For example, a Drug Abuse Treatment Outcomes Study by the National Institute on Drug Abuse (NIDA) found that methadone treatment reduced participants’ heroin use by 70%, reduced criminal activity by 57%, and increased full-time employment by 24%.<sup>1</sup> It also reduces deaths—the median death rate of opiate-dependent individuals in treatment is 30 percent of the rate of those not in treatment.<sup>2</sup></p>
          <p>Suboxone, a medication combining buprenorphine and naloxone, has demonstrated significant efficacy in treating opioid use disorder (OUD), as supported by historical evidence. Numerous studies have consistently shown its effectiveness in reducing opioid cravings, preventing withdrawal symptoms, and promoting long-term recovery. A randomized controlled trial conducted by Ling et al. (2005) involving 326 participants with OUD found that Suboxone was superior to both placebo and buprenorphine alone in reducing illicit opioid use. The study revealed that Suboxone treatment resulted in significantly higher rates of treatment retention, lower rates of opioid-positive urine samples, and greater reductions in self-reported illicit opioid use.<sup>3</sup></p>
          <p>Moreover, additional evidence supporting the efficacy of Suboxone comes from a systematic review and meta-analysis by Mattick et al. (2014). The review analyzed data from 31 studies involving over 5,400 participants with OUD. The findings demonstrated that Suboxone was consistently associated with decreased illicit opioid use, improved treatment retention, and reduced mortality rates compared to placebo and non-pharmacological interventions. Furthermore, the review highlighted the importance of Suboxone in reducing the risk of HIV infection and criminal activity among individuals with OUD. These historical studies and reviews provide robust evidence of Suboxone's efficacy as a treatment option for opioid use disorder.<sup>4</sup></p>
        </div>
        
          <div>
            <h1 className='purple'>References</h1>
            <ol>
              <li>McCaffrey, Barry, Statement of ONDCP Director Barry McCaffrey on Mayor Giuliani’s Recent Comments on Methadone Therapy, (Press Release) (Washington, DC: ONDCP), July 24, 1998.</li>
              <li>CDC publication on IDU HIV Prevention. Methadone Maintenance Treatment, February 2002.</li>
              <li>Ling, W., Amass, L., Shoptaw, S., Annon, J. J., Hillhouse, M., Babcock, D., ... & Bickel, W. K. (2005). A multicenter randomized trial of buprenorphine–naloxone versus clonidine for opioid detoxification: findings from the National Institute on Drug Abuse Clinical Trials Network. Addiction, 100(8), 1090-1100.</li>
              <li>Mattick, R. P., Breen, C., Kimber, J., & Davoli, M. (2014). Buprenorphine maintenance versus placebo or methadone maintenance for opioid dependence. Cochrane Database of Systematic Reviews, (2), CD002207.</li>
            </ol>
            <h1 className='purple'>Additional Sources</h1>
            <ul>
              <li>SAMHSA National Clearinghouse for Drug and Alcohol Information</li>
              <li>Office of National Drug Control Policy Drug Facts</li>
            </ul>
        </div>
          </div>
        </div>
      <QuickContact />
    </div>
  </div>
</div>

  )
}

function Counseling() {
  return (
  <div>
    <div className='banner'>COUNSELING & CASE MANAGEMENT</div>
    <div className='counsel'>
    <div className='q-contact'>
    <div className='counsel-info'>
          <div className='box-shadow counsel-item'>
            <h1 className='purple bold'>Counseling is Integral to A Recovery Program</h1>
            <img src={require('../images/counsel.png')} className="counsel-img" alt='...'/>
          <div>
            <p><span className='green'>At SOAP MAT, LLC, we strive to provide comprehensive and progressive care to our patients.</span> As licensed psychologists, our co-owners and founders place great importance on the counseling component of treatment. In addition to our existing services, we are delighted to offer case management as a valuable service to our patients.</p>
            <p>To ensure personalized care, each patient at SOAP MAT is assigned a dedicated personal counselor. The frequency of counseling sessions is determined based on the duration of treatment and the patient's progress. We also incorporate feedback from treatment to adjust the scheduling accordingly. To monitor progress, our program regularly conducts random urine drug screens to detect the presence of any illicit substances. These screenings are typically performed at least once a month, but they may be increased if clinically necessary or mandated by the patient's specific needs.</p>
            <p>Upon completion of the intake assessment, the primary counselor develops an individualized treatment plan for the patient. As the patient progresses through treatment and adheres to the treatment plan, the treatment team collaboratively evaluates eligibility for reduced counseling sessions and take-home medication privileges. If deemed appropriate, these privileges may be granted to the patient, empowering them in their recovery journey.</p>
            <p>To ensure the ongoing effectiveness of the treatment, all treatment plans are regularly reviewed and updated every 90 days by the primary counselor and the treatment team. This collaborative effort ensures that the patient's evolving needs are met, and their goals remain attainable throughout their treatment stay. With our case management service, we are committed to providing comprehensive care that addresses both the counseling component and the overall well-being of our patients.</p>
          </div>
          </div>
        </div>
      <QuickContact />
    </div>
    </div>
  </div>
  );
}

function Medications() {
  return (
    <div>
      <div className='banner'>MEDICATIONS</div>
      <div className='med-section'>
      <div className='q-contact'>
      <div className='med-grid'>
        <div className='med-grid-item box-shadow'>
          <h3 className='purple bold'>Methadone</h3>
          <p>Methadone is a synthetic opioid agonist that helps manage withdrawal symptoms and cravings associated with opioid use disorder. It works by binding to the same receptors in the brain that opioids target, thereby reducing withdrawal symptoms and relieving cravings.</p>
          <a href="https://www.samhsa.gov/medications-substance-use-disorders/medications-counseling-related-conditions/methadone" className='link' target='_blank' rel="noopener noreferrer">Detailed Information</a>
        </div>
        <div className='med-grid-item box-shadow'>
          <h3 className='purple bold'>Suboxone</h3>
              <p>Suboxone is a combination medication that contains buprenorphine and naloxone. Buprenorphine helps reduce withdrawal symptoms and cravings, while naloxone blocks the effects of other opioids. Suboxone is typically administered as a sublingual film or tablet. </p>
              <a href="https://www.samhsa.gov/medications-substance-use-disorders/medications-counseling-related-conditions/buprenorphine" className='link' target='_blank' rel="noopener noreferrer">Detailed Information</a>
        </div>

        <div className='med-grid-item box-shadow'>
          <h3 className='purple bold'>Subutex</h3>
          <p>Buprenorphine is a partial opioid agonist that also helps with opioid use disorder. It activates the same receptors as opioids but to a lesser extent, providing relief from withdrawal symptoms and reducing cravings. Buprenorphine is available in various forms, including sublingual tablets, sublingual film, and extended-release injections.</p>
          <a href="https://www.samhsa.gov/medications-substance-use-disorders/medications-counseling-related-conditions/buprenorphine" className='link' target='_blank' rel="noopener noreferrer">Detailed Information</a>
        </div>
            
        <div className='med-grid-item box-shadow'>
          <h3 className='purple bold'>Naltrexone</h3>
          <p>Naltrexone is an opioid antagonist that blocks the effects of opioids in the brain. It is primarily used in the treatment of opioid use disorder, however, it can also be prescribed for alcohol use disorder (AUD). Naltrexone helps reduce the rewarding effects of alcohol and decreases the desire to drink. It can be administered orally or as an extended-release injection called Vivitrol.</p>
          <a href="https://www.samhsa.gov/medications-substance-use-disorders/medications-counseling-related-conditions/naltrexone" className='link' target='_blank' rel="noopener noreferrer">Detailed Information</a>
        </div>

        <div className='med-grid-item box-shadow'>
          <h3 className='purple bold'>Naloxone</h3>
          <p>Naloxone, also known by the brand name Narcan, is an opioid antagonist used to rapidly reverse the effects of an opioid overdose. It is administered as a nasal spray or injection and can quickly restore normal breathing and prevent fatal consequences of opioid overdose.</p>
          <a href="https://www.samhsa.gov/medications-substance-use-disorders/medications-counseling-related-conditions/naloxone" className='link' target='_blank' rel="noopener noreferrer">Detailed Information</a>
        </div>
        <div className='med-grid-item box-shadow'>
          <h3 className='purple bold'>Coming Soon to Soap MAT: Disulfiram & Sublocade</h3>
          <p>Disulfiram is a medication used to treat alcohol misuse. It works by interfering with the breakdown of alcohol in the body, leading to the accumulation of a toxic substance called acetaldehyde. Sublocade is a medication used in the treatment of opioid use disorder (OUD). It contains the active ingredient buprenorphine, which is an opioid partial agonist. Once injected, Sublocade releases buprenorphine slowly over the course of a month, providing continuous medication coverage.</p>
          <a href="https://www.samhsa.gov/medications-substance-use-disorders/medications-counseling-related-conditions" className='link' target='_blank' rel="noopener noreferrer">Detailed Information </a>
        </div>
      </div>
        <QuickContact />
      </div>
    </div>
  </div>
  )
}

function Perinatal() {
  return (
    <div>
      <div className='banner'>PERINATAL SERVICES</div>
      <div className='perinatal'>
      <div className='q-contact'>
      <div className='perinatal-info'>
          <div className='box-shadow peri-item'>
            <h1 className='purple bold'>MAT During Pregnancy</h1>
            <img src={require('../images/peri-img.png')} className="peri-img" alt='...'/>
            <div>
              <p><span className='green'>Pregnant or breastfeeding individuals can safely use methadone as a harm-reduction approach.</span> When a pregnant person experiences withdrawal from a substance they have been misusing, it can lead to contractions in the uterus and potentially result in miscarriage or premature birth. Methadone helps prevent withdrawal symptoms, supporting pregnant individuals in effectively managing their addiction while minimizing health risks for both themselves and their baby.</p>
              
              <p>Choosing to undergo methadone maintenance treatment during pregnancy does not cause birth defects. However, it's important to note that some babies may experience withdrawal symptoms after birth. It's crucial to understand that this does not indicate addiction in the baby. The onset of infant withdrawal typically occurs a few days after birth but may also begin between two to four weeks later.</p>
              <p>Mothers who are taking methadone can continue to breastfeed. Research has demonstrated that the benefits of breastfeeding outweigh the impact of the small amount of methadone present in breast milk. If a woman is considering discontinuing methadone treatment due to concerns about breastfeeding or pregnancy, it is recommended to consult with her doctor first for guidance and support.</p>
              <p>Misusing heroin during pregnancy, coupled with factors like inadequate prenatal care and poor nutrition, is also associated with low birth weight, which increases the risk of developmental delays in infants. If the mother consistently misuses the drug, the infant may be born physically dependent on heroin and could experience neonatal abstinence syndrome (NAS), a withdrawal syndrome that requires hospitalization.</p>
              <p>It is essential for pregnant or breastfeeding individuals to inform their treatment provider before starting methadone. Methadone is the only medication approved for Medication-Assisted Treatment (MAT) that can be safely used by pregnant or breastfeeding women.</p>
            </div>
          </div>
        </div>
        <QuickContact />
      </div>
    </div>
  </div>
  )
}

function Youth() {
    return (
      <div>
        <div className='banner'>YOUTH SERVICES</div>
        <div className='youth'>
        <div className='q-contact'>
        <div className='youth-cont'>
          <div className='youth-info'>
            <div className='yg-item box-shadow'>
                <h1 className='purple bold'>Helping the youth take the right steps</h1>
                <h3 className='green'>Age group: 12 - 17 years</h3>
                <img src={require('../images/youth-img.png')} className="youth-img" alt='...'/>
            <div>
              <p>With the support of the county of San Diego, SOAP MAT has started to provide services to the youth population who are currently experiencing substance use disorders. </p>
              <p>At SOAP MAT, we're here to support young individuals in overcoming substance misuse and building a healthier life. Our youth substance abuse treatment program offers personalized counseling, case management services, and medication-assisted treatment. We address the root causes of addiction and provide essential coping skills. Our holistic approach includes promoting healthy lifestyle choices and fostering a supportive community. </p>

              <p>
                It is important to note that there are significant high-risk factors that are applicable to youth populations that greatly impact the potential for experiencing and requiring treatment for substance abuse disorders. From studies throughout the years we can see that there are a multitude of factors that go into the development of substance use disorders. A large portion of the current literature is dictating that youth risk factors have a significant impact on the likelihood of substance use disorder development. Below are some of the high-risk factors provided by the CDC:
              </p>
              <ul>
                <li className='square'>Family history of substance use</li>
                <li className='square'>Favorable parental attitudes towards the behavior</li>
                <li className='square'>Poor parental monitoring</li>
                <li className='square'>Parental substance use</li>
                <li className='square'>Family rejection of sexual orientation or gender identity</li>
                <li className='square'>Association with delinquent or substance using peers</li>
                <li className='square'>Lack of school connectedness</li>
                <li className='square'>Low academic achievement</li>
                <li className='square'>Childhood sexual abuse</li>
                <li className='square'>Mental health issues</li>
              </ul>
            </div>
          </div>
          </div>
        </div>
          <QuickContact />
        </div>
      </div>
    </div>
    )
  }

export { 
    Programs,
    Counseling,
    Medications,
    Perinatal,
    Youth
    };





