export const medicationsArray = [
  {
    name: "Methadone",
    description: [
      "Methadone is a synthetic opioid agonist that helps manage withdrawal symptoms and cravings associated with opioid misuse",
      "It reduces withdrawal symptoms and relieving cravings",
      "It is provided in liquid form at our treatment facility"
    ]
  },  
    {
      name: "Suboxone",
      description: [
      "Suboxone is a combination medication that contains buprenorphine and naloxone", 
      "Buprenorphine helps reduce withdrawal symptoms and cravings associated with opioid misuse, while Naloxone blocks the effects of other opioids", 
      "Suboxone is administered as a sublingual film or tablet"
    ]
    },
    {
      name: "Subutex",
      description: [
        "Subutex is a medication that is used in the treatment of opioid misuse disorder",
        "It works by binding to the same receptors in the brain that opioids bind to",
        "Available in various forms, including sublingual tablets, sublingual film, and coming soon to our facility, extended-release injections"
      ]
    },
    {
      name: "Naltrexone",
      description: [
        "Naltrexone is a medication commonly used in the treatment of opioid misuse and alcohol use disorder (AUD)",
        "For AUD, naltrexone helps reduce the cravings associated with alcohol and decreases the desire to drink",
        "For opioid misuse, naltrexone helps prevent the euphoric effects of opioids, reduces cravings, and also works to prevent relapse"
    ]
    },
    {
      name: "Upcoming",
      upcomingMeds: ["Disulfiram", "Sublocade"],
      descriptions: [
        "Disulfiram is a medication used to treat alcohol misuse. It works by interfering with the breakdown of alcohol in the body, leading to the accumulation of a toxic substance called acetaldehyde.",
        "Sublocade is a medication used in the treatment of opioid use disorder (OUD). It contains the active ingredient buprenorphine, which is an opioid partial agonist. Once injected, Sublocade releases buprenorphine slowly over the course of a month, providing continuous medication coverage."
      ],
    },
  ];
  
