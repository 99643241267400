import React from "react";
import { Link } from "react-router-dom";


export default function Navbar() {
  return (
    <nav className="navbar sticky-top navbar-expand-lg bg-white">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
            <img src={require('../images/logo2.png')} alt="Soap Logo" className="logo"/>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className="nav-link px-3" to="/" >
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle px-3"
                
                role="button"
                aria-expanded="false"
              >
                About Us
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/about/soap-mat">
                    About SOAP MAT
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="about/our-staff">
                    Our Staff
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="about/faq">
                    FAQ's
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/about/interview">
                    Dr. Bart Rossi Interview
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle px-3"
                role="button"
                aria-expanded="false"
              >
                Services
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="services/programs">
                    Programs
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="services/case-management-counseling"
                  >
                    Case Management & Counseling
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="services/medications">
                    Medications
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="services/perinatal-health">
                    Perinatal Health
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="services/youth">
                    Youth
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle px-3"
                role="button"
                aria-expanded="false"
              >
                Educational Materials
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/educational-materials/substance-use">
                    Substance Use Disorders
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/educational-materials/chemical-dependency">
                    Chemical Dependency
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-3" to="/contact">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-3" to="/resources">
                Resources
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}




