import React from "react";
import QuickContact from './QuickContact';

function Payments() {
    return (
        <div>
            <div className='banner'>Payment Options</div>
            <div className="payment">
            <div className="q-contact">
            <div className="pay-grid">
                <div className="pay-info box-shadow">
                    <h4 style={{ textAlign: 'left' }} className="purple">Medi-Cal Coverage</h4>
                    <p>Substance use treatment is covered under Medi-Cal if it is medically necessary and provided in an inpatient or outpatient treatment center that is Medi-Cal-certified, as determined by the Department of Health and Human Services (HHS). SOAP MAT, LLC is a Medi-Cal-certified treatment facility and accepts Medi-Cal as a form of payment for eligible individuals.</p>

                    <h4 style={{ textAlign: 'left' }} className="purple">Medicare Coverage</h4>
                    <p>Substance use treatment is covered under Medicare if it is medically necessary and provided in an inpatient or outpatient treatment center that is Medicare-certified. SOAP MAT, LLC is a Medicare-certified treatment facility and accepts Medicare as a form of payment for eligible individuals.</p>

                    <h4 style={{ textAlign: 'left' }} className="purple">Commercial Insurance Coverage</h4>
                    <p>SOAP MAT currently accepts three commercial insurances for coverage of addiction treatment services. These include:</p>
                    <ul>
                        <li className="square">United Health Plan</li>
                        <li className="square">Beacon</li>
                        <li className="square">Optum</li>
                    </ul>
                    <p>If you have commercial insurance coverage through any of these providers, SOAP MAT will work with your insurance company to facilitate payment for your treatment. Please note that coverage specifics may vary depending on your individual insurance plan, and it is recommended to contact your insurance provider for detailed information about your coverage.</p>

                    <p>In addition to the aforementioned insurances currently accepted; we are dedicated to increasing the amount of contracted insurance providers to services a wider range of the San Diego population.
                    </p>

                    <p>People Who Are Uninsured:
                    For individuals who are not eligible for Medicare or do not have insurance coverage, they will be responsible for paying for prescribed medications and any associated treatment out-of-pocket. SOAP MAT understands the financial burden this may pose and offers an affordable monthly payment plan. The details of the payment plan will be discussed during the admissions process, providing individuals with a clear understanding of the cost and payment options available.
                    </p>
                </div>

                </div>
                <QuickContact />
            </div>
            </div>
        </div>
    )
}

export default Payments;