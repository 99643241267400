
import React, { useState } from 'react';

const RenewalBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  return (
    <div>
      {showBanner && (
        <div className="renewal-banner">
        <div className='rb-flex'>
            <p className="r-banner">Have you completed your renewal process for Medi-Cal? You may need to update your information or re-enroll. </p>
            <a href='https://www.dhcs.ca.gov/pages/keep-your-medi-cal.aspx' target="_blank" rel="noopener noreferrer" className='rb-link'>California's Medi-Cal Renewal Process</a>
        </div>
          <button className="close-btn" onClick={handleCloseBanner}>X</button>
        </div>
      )}
    </div>
  );
};

export default RenewalBanner;

