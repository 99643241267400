import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const FaqContainer = () => {
  useEffect(() => {
    const questions = document.querySelectorAll(".faqs-container .question");

    questions.forEach((header, i) => {
      header.addEventListener("click", () => {
        header.nextElementSibling.classList.toggle("active");

        const open = header.querySelector(".open");
        const close = header.querySelector(".close");

        if (header.nextElementSibling.classList.contains("active")) {
          open.classList.remove("active");
          close.classList.add("active");
        } else {
          open.classList.add("active");
          close.classList.remove("active");
        }
      });
    });

    return () => {
      questions.forEach((header, i) => {
        header.removeEventListener("click", () => {});
      });
    };
  }, []);

  return (
    <div className="faqs-container box-shadow">
      <h1 className='purple bold'>FREQUENTLY ASKED QUESTIONS</h1>
      <div className='question-container'>
        <div className='content-container'>
          <div className='question'>
            <h3 className='purple bold'>Does SOAP MAT accept Medi-Cal beneficiaries?</h3>
            <span className='open green' style={{fontSize: '1.2em' }}>+</span>
            <span className='close green' style={{fontSize: '1.2em' }}>-</span>
          </div>
          <div className='answer'>
            <p>
              Yes, SOAP MAT is proud to service all Medi-Cal beneficiaries that reside within the San Diego county. We also offer additional payment options, see payment options page <Link to="/payments" className='purple'>HERE</Link>
            </p>
          </div>
        </div>
      </div>

    <div className='question-container'>
      <div className='content-container'>
              <div className='question'>
                <h3 className='purple bold'>Can I choose my medication?</h3>
                <span className='open green' style={{fontSize: '1.2em' }}>+</span>
                <span className='close green' style={{fontSize: '1.2em' }}>-</span>
              </div>
              <div className='answer'>
                <p>
                  We believe heavily in a patient's autonomy to choose an appropriate medication. With that being said, all prescribed medication through our services is determined through consistent and extensive consultation with our medical director. Additional qualifying factors for medications are present as well.
                </p>
              </div>
          </div>
      </div>

  <div className='question-container'>
    <div className='content-container'>
              <div className='question'>
                <h3 className='purple bold'>What days can I come in for intake?</h3>
                <span className='open green' style={{fontSize: '1.2em' }}>+</span>
                <span className='close green' style={{fontSize: '1.2em' }}>-</span>
              </div>
              <div className='answer'>
                <p>
                SOAP MAT offers walk-in intake services Monday through Friday from 5am-2pm to ensure that all patients have as many opportunities to engage in treatment as feasible.
                </p>
              </div>
          </div>
       </div>

  <div className='question-container'>
    <div className='content-container'>
              <div className='question'>
                <h3 className='purple bold'>Do I have to engage in counseling while in treatment? </h3>
                <span className='open green' style={{fontSize: '1.2em' }}>+</span>
                <span className='close green' style={{fontSize: '1.2em' }}>-</span>
              </div>
              <div className='answer'>
                <p>
                Yes. All patients engaged with SOAP MAT are required to engage in substance abuse related counseling sessions (quantity and duration of sessions is based on a multitude of factors including time in treatment, insurance requirements, etc.)
                </p>
              </div>
          </div>  
        </div>
    </div>
  );
};

export default FaqContainer;
