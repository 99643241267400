import React from 'react';
import QuickContact from './QuickContact';

function Resources() {
    return (
    <div>
        <div className='banner'>RESOURCES</div>
        <div className='resources'>
        <div className='q-contact'>
        <div className='grid-container' >
            <div className='grid-item'>
                <h1 className='purple'>San Diego County Health and Human Services Agency</h1>
                <a href='https://www.sandiegocounty.gov/hhsa' className='website' target="_blank" rel="noopener noreferrer"><span className='purple'>Website: </span>https://www.sandiegocounty.gov/hhsa</a>
                <p className='phone'><span className='purple'>Behavioral Health Services Helpline: </span>1-888-724-7240</p>
                <p>Offers substance abuse treatment programs, counseling, crisis intervention, and emergency assistance.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>McAlister Institute</h1>
                <a href='https://www.mcalisterinc.org/' target="_blank" rel="noopener noreferrer" className='website'> <span className='purple'>Website: </span>https://www.mcalisterinc.org/</a>
                <p className='phone'><span className='purple'>Phone: </span> 619-442-0277</p>
                <p>Provides detoxification, residential treatment, outpatient programs, and recovery support services.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Soledad House</h1>
                <a href='https://www.soledadhouse.com/' target="_blank" rel="noopener noreferrer" className='website'> <span className='purple'>Website: </span>https://www.soledadhouse.com/</a>
                <p className='phone'><span className='purple'>Phone:</span> 866-314-3222</p>
                <p>Offers gender-specific residential and outpatient treatment programs for women.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Mental Health Systems</h1>
                <a href='https://www.mhsinc.org/' target="_blank" rel="noopener noreferrer" className='website'> <span className='purple'>Website: </span>https://www.mhsinc.org/</a>
                <p className='phone'><span className='purple'>Phone: </span> 858-573-2600</p>
                <p>Provides substance abuse treatment services, including detoxification and outpatient programs.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Aton Center</h1>
                <a href='https://www.atoncenter.com/' target="_blank" rel="noopener noreferrer" className='website'> <span className='purple'>Website: </span>hhttps://www.atoncenter.com/</a>
                <p className='phone'> <span className='purple'>Phone: </span> 888-380-2641</p>
                <p>Offers residential and outpatient treatment programs for substance abuse and co-occurring disorders.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Lasting Recovery</h1>
                <a href='https://www.lastingrecovery.com/' target="_blank" rel="noopener noreferrer" className='website'><span className='purple'>Website: </span>https://www.lastingrecovery.com/</a>
                <p className='phone'><span className='purple'>Phone: </span> 858-453-4315</p>
                <p>Provides outpatient substance abuse treatment programs, counseling, and holistic therapies.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Alpha Project</h1>
                <a href='https://www.alphaproject.org/' target="_blank" rel="noopener noreferrer" className='website'><span className='purple'>Website: </span>https://www.alphaproject.org/</a>
                <p className='phone'><span className='purple'>Phone: </span> 619-542-1877</p>
                <p>Offers substance abuse treatment, transitional housing, and employment assistance for homeless individuals.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Veteran's Village of San Diego:</h1>
                <a href=' https://vvsd.net/' target="_blank" rel="noopener noreferrer" className='website'><span className='purple'>Website: </span> https://vvsd.net/</a>
                <p className='phone'><span className='purple'>Phone: </span> 619-497-0142</p>
                <p>Provides substance abuse treatment programs, mental health support, housing assistance, and job training for veterans.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Alcoholics Anonymous San Diego</h1>
                <a href='https://www.aasandiego.org/' target="_blank" rel="noopener noreferrer" className='website'><span className='purple'>Website: </span>https://www.aasandiego.org/</a>
                <p className='phone'><span className='purple'>Phone: </span> 619-265-8762</p>
                <p>Offers support and information on Alcoholics Anonymous meetings in the San Diego area.</p>
            </div>
            <div className='grid-item'>
                <h1 className='purple'>Narcotics Anonymous San Diego</h1>
                <a href='https://www.sandiegonarcotics.com/' target="_blank" rel="noopener noreferrer" className='website'><span className='purple'>Website: </span>https://www.sandiegonarcotics.com/</a>
                <p className='phone'><span className='purple'>Helpline: </span> 619-584-1007</p>
                <p>Provides information and resources for Narcotics Anonymous meetings in San Diego.</p>
            </div>
        </div>
            <QuickContact />
        </div>
    </div>
</div>
    )
}

export default Resources;