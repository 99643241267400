import React from 'react';

function Contact() {
  return (
    <div>
      <div className='banner'>CONTACT US</div>
      <div className='contact-flex'>
        <div className="flexbox-container">
          <div className="flexbox-item">
            <div className="label purple">Address</div>
            <div>
              SOAP MAT, LLC<br />
              3230 Waring Court  <br />Suite A<br />
              Oceanside, CA 92056
            </div>
          </div>
          <div className="flexbox-item">
            <div className="label purple">Business Hours</div>
            <div>
              Monday - Friday: 5 AM - 2 PM<br />
              Saturday - Sunday: 6AM - 10 AM<br />
              Holidays: 7 AM - 9:30 AM
            </div>
          </div>
          <div className="flexbox-item">
            <div className="label purple">Phone</div>
            <div>Oceanside Office: <br />(760) 305-7528</div>
          </div>
          <div className="flexbox-item">
            <div className="label purple">FAX</div>
            <div>(760) 509-4410</div>
          </div>
          <div className="flexbox-item">
            <div className="label purple">Email</div>
            <div>info@soapmat.com</div>
          </div>
        </div>
        <div className='bus-locations box-shadow'>
          <div className='location-cont'>
            <div>
              <h3 className='purple'>Business Hours</h3>
              <p>Please visit us during these times:</p>
              <p>Monday - Friday: 5 AM - 2 PM</p>
              <p>Saturday - Sunday: 6 AM - 10 AM</p>
              <p>Holidays: 7 AM - 9:30 AM</p>
            </div>
            <div>
              <i className='bx bx-map'></i>
              <p>SOAP MAT, LLC</p>
              <p>3230 Waring Court | Suite A</p>
              <p>Oceanside, CA 92056</p>
            </div>
            <div>
              <i className='bx bx-map'></i>
              <p>NEW LOCATIONS (Coming Soon)</p>
              <div>
              <p>1929 W Vista Way</p>
              <p>Vista, CA 92083</p>
              </div>
              <br />
              <div>
                <p>6423 University Ave</p>
                <p>San Diego, CA 92115</p>
              </div>
            </div>
          </div>
          <img src={require('../images/map.PNG')} className="map box-shadow" alt="..." />
        </div>
      </div>
    </div>
  );
}

export default Contact;

