import React from 'react';
import FaqContainer from './FAQ';
import QuickContact from './QuickContact';
import { Link } from "react-router-dom";

function AboutSoap() {
  return (
  <div>
    <div className='banner'>ABOUT US</div>
    <div className='about'>
      <div className='q-contact'>
      <div className='about-info'>
        <div className='about-soap'>
        <div className='about-items box-shadow' style={{ textAlign: 'left' }}>
          <h1 className='bold purple'>OUR MISSION</h1>
          <p><span className='green'>The mission of SOAP MAT, LLC is to provide a high quality outpatient opiate treatment program (OTP) that promotes health and well-being to an underserved opiate dependent population.</span> The objective of the program will be to provide affordable, medically assisted treatment (MAT) utilizing the harm reduction model, focusing on each patient as an individual. The goal of treatment will therefore be to enable the community to function at a higher level, yielding a decrease in crime rates, high risk behaviors and deaths. To that end, SOAP MAT, LLC will promote sobriety, as evidenced by an absence of abusing illicit opioids.</p>
        </div>
        <div className='about-items box-shadow' style={{ textAlign: 'left' }}>
          <h1 className='bold purple' style={{ textAlign: 'left' }}>ABOUT SOAP MAT</h1>
          <p>At SOAP MAT LLC, we offer a comprehensive range of services aimed at supporting individuals' overall well-being. Our services include counseling, case management, social work, referrals to community resources, educational programs, work eligibility, vocational rehabilitation, and nutritional counseling. We prioritize compliance with various governmental regulatory agencies, including the Federal Drug Enforcement Administration (DEA), the Substance Abuse and Mental Health Services Administration / Center of Substance Abuse Treatment (SAMHSA/CSAT), the Council on Accreditation (COA), the Food and Drug Administration (FDA), and the California Department of Health Care Services. We are committed to delivering high-quality care and ensuring that our clients have access to the resources they need to achieve their goals.</p>
        </div>

        <div className='about-items box-shadow' style={{ textAlign: 'left' }}>
        <h1 className='purple bold'>FOUNDERS</h1>
          <div className='founder' style={{ textAlign: 'left' }}>
            <p>Here at SOAP MAT, LLC we are founded and led by two experienced and dedicated professionals in the field of psychology and substance abuse disorders. With the combined expertise of Dr. Bart Rossi and Dr. Laura Rossi, our organization is committed to providing high-quality psychological care and making a positive impact on the lives of our patients</p>

            <p style={{ textAlign: 'left' }}><span className='bold purple'>Dr. Bart Rossi</span>, with a Ph.D. in General-Theoretical Psychology from Fordham University, has an extensive background in providing psychological care. Throughout his career, he has worked with diverse clientele in various settings, including private practice, medical centers, and organizations statewide. From 1977 to 2013, he served as the CEO of the Rossi Psychological Group, P.A., in Somerville, New Jersey, overseeing a team of 50 licensed psychologists. Dr. Rossi's expertise extends beyond clinical practice, as he has been involved in directing the Independent Child Study Teams, Inc. and the Adolescent Counseling Center of Somerset County. Additionally, he has collaborated with institutions such as the New Jersey Parole Board, the New Jersey Department of Corrections, and the Hudson County Employee Assistance Program. Dr. Rossi's contributions have been recognized, and he has been invited as a keynote speaker at the American Psychological Association Annual Practice Directorate Meeting. <span>  <Link to="/about/interview" className='intv-link'>Link to Interview</Link>
             </span></p>
          </div>
          <div className='founder'>
            <p style={{ textAlign: 'left' }}> <span className='bold purple'>Dr. Laura Rossi</span>, a licensed clinical and forensic psychologist, brings her knowledge and experience in working with high-risk populations to our organization. With a Ph.D. in Clinical and Forensic Psychology from the California School of Professional Psychology, she has dedicated 15 years to the field. Dr. Rossi's career began in the prison system, conducting forensic psychological evaluations and testifying as an expert witness in court. Her work also involved assessing high-risk youth and adults living in challenging environments. In 2005, Dr. Rossi shifted her focus to working at an opioid treatment program, advocating for medication-assisted treatment and implementing patient-focused and harm reduction models. She believes in treating each patient with dignity and respect. Dr. Rossi actively participates in various professional associations and community organizations, including the Department of Health Care Services NTP Advisory Board, California Psychological Association, Democratic Club of Oceanside and Carlsbad, CA, Council on Accreditation, COMP Member, and Prescription Drug Task Force of San Diego.
            </p>
          </div>
        </div>
      </div>
      </div>
          <QuickContact />
      </div>
    </div>
  </div>
  ) 
}

function OurStaff() {
  return (
      <div>
      <div className='banner'>OUR STAFF</div>
      <div className='our-staff'>
      <div className='q-contact'>
      <div className='staff'>
        <div className='staff-info box-shadow'>
        <h1 className='purple bold'>OUR VALUABLE TEAM MEMBERS</h1>
          <p className='larger-font'>The SOAP MAT team is comprised of an executive team, medical department, nursing staff, counseling staff, administrative staff and security team.</p>
          <img src={require('../images/staff.png')} className="staff-img" alt="..." />
          <h3 className='bold purple clear-prop' style={{textAlign: 'left'}}>SOAP MAT Staff is:</h3>
            <ul>
              <li className="square">Patient focused and empathetic</li>
              <li className="square">Highly experienced and certified</li>
              <li className="square">Professional, warm, caring</li>
              <li className="square">Harm-reduction oriented</li>
              <li className="square">Committed to patient education and advocacy</li>
            </ul>
            <h3 className='bold purple' style={{textAlign: 'left'}}>SOAP MAT, LLC team members consist of the following:</h3>
            <ul>
              <li className="square">Licensed clinical psychologists</li>
              <li className="square">Licensed physicians</li>
              <li className="square">Licensed clinical social worker</li>
              <li className="square">Medical directors</li>
              <li className="square">Certified drug & alcohol counselors / Case Managers</li>
              <li className="square">Master’s level certified substance use disorder counselors / Case Managers</li>
              <li className="square">Licensed nurses (RN & LVN)</li>
            </ul>
      </div>
    </div>
      <QuickContact />
    </div>
  </div>
</div>
  );
}

function FAQ() {
  return (
    <div>
      <div className='banner'>FAQ's</div>
      <div className='faq-cont'>
      <div className='q-contact'>
      <div className='faq-info'>
        <FaqContainer />
        </div>
        <QuickContact />
      </div>
    </div>
  </div>
  )
}

function Interview() {
  return (
    <div>
    <div className='banner'>Interview With Addicts Family Lifeline</div>
    <div className='interview'>
      <div className='q-contact'>
        <div className='interview-info'>
          <div className='box-shadow intv-box'>
            <h2 className='green bold' style={{textAlign: 'left'}}>Dr. Rossi interviewed by Denise Krochta, President at Addicts Family Lifeline, Inc.</h2>
            <h3 className='purple'>Summary</h3>
            <p>“What do we know about methadone and methadone clinics? Denise really didn’t know that much about either but has been getting many inquiries from her listeners and decided it was time to find out the who, why, where, and what about them. Listen and learn about the possibilities and the details.”</p>
            <a href="http://webtalkradio.net/internet-talk-radio/2015/02/02/addicted-to-addicts-survival-101-methadone-and-methadone-clinics/" target="_blank" rel="noopener noreferrer" className='green'><h3>Click Here to Listen </h3></a>
          </div>
          <div className='box-shadow intv-sect'>
            <h2 className='purple'>“US Military and Mental Health Issues”</h2>
            <p>At this time the United States is suffering from a lack of understanding in all areas of mental health. This is because the stigma has never been removed from mental health treatment. Treating physical problems has always been accepted in our society but psychological problems and issues are seen negatively. Many believe psychological/mental health issues show a sign of personal weakness. Actually everyone has a personality with many “sides” and specific features and some of those “sides” are far from perfect and need personal development. The complete lack of understanding of the signs and symptoms of mental health issues and what is effective treatment may be commonplace but the US Military seems have a history marked by insensitivity.</p>
            <p>For many years the US military has had a particular “mental set” that promotes a certain type of thinking that ignores psychological and mental health issues. The recruit has been expected to simply comply with the “way” the military presents itself. Now there is certainly the need for an effective US military to have compliance, regulations, rules, and a specific team approach, but ignoring psychological and mental health considerations has led us to where we are today. Currently the military is fixated on prescribing one psychotropic medication on top of another to solve real life problems. This is bound to fail and no one really understands the effects of all the drugs interacting on the human body and resulting behavior. Furthermore there seems to be a complete lack of understanding with regard to the effectiveness of Cognitive Behavioral Techniques that psychologists now deliver with remarkable success in many areas.</p>
            <p>In the near future the US military will be facing more problems given the obesity epidemic in this country and the fact that more teens have psychological and mental health issues. How is the US military going to recruit young men and women given a limited pool of reasonably responsible and capable young teenagers? The answer to this question is twofold. First the military must better understand personality, psychological considerations , and mental health issues. In fact, some individuals may have “issues” but could be effective in the military if they are evaluated correctly and placed properly in the military system. More carefully evaluated possible recruits by licensed psychologists who are experts in personality, psychological testing and evaluation, and interviewing could make a huge difference in who is effectively recruited and where they may be placed for meaningful service. Secondly, the US military must come up with better ideas to recruit teens. Certainly a career in military service has been improved significantly in recent years. However, more positive incentives and an more “sensitive” military is needed to recruit young people who will serve with honor and distinction.</p>
            <p>The fact that teens today suffer more readily from anxiety, peer rejection, depression, and a lack of personal control may be at least partially the result of social media (computer age) and a different family life. Today it is widely viewed that both parents must/should work and this by itself is not a bad example for children. But often teens are uncertain of themselves and where they are going in life. The pressures of social media and being part of the “peer group” affect teens in ways that were never dealt with before. Even bullying has become more of a problem since teens can be “outed” or marginalized through social media. These everyday pressures resulting in psychological/physical/emotional problems are an issue for all of us but the military must become more knowledgeable, flexible, and insightful.</p>
            <p>The future of the US military should not be simply; “Attract the Brightest and Best.” The US military should be looking for those teens who can still be very effective but do in fact have “sides” to their personality that need further development. Today there are obvious examples of individuals who may not be especially promising in one area but are great with computers, technical support, and specific tasks not on the field of combat. Conversely, there may be teens with personal issues who could still be very effective on the battlefield given their communication skills, desire, dedication, and physical prowess. Obviously, the US military needs better psychological assessment if everyone with their own unique skill set is to be best considered. The “old” view that everyone must fit into a rigid system that has a marginal understanding of individual differences and psychological issues is now a system that will not work.</p>
            <h2 className='purple bold'>-Bart Rossi, Ph.D</h2>
          </div>
        </div>
        <QuickContact />
      </div>
    </div>
  </div>
  )
}


export { AboutSoap, OurStaff, FAQ, Interview };


