import React from "react";
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <div>
                <p>
                    <h2>SOAP MAT, LLC</h2>
                    <i className='bx bxs-map'></i> 
                    3230 Waring Court | Suite A
                    Oceanside, CA 92056
                </p>
                <p>
                    <i className='bx bxs-phone'></i>
                    Phone: (760) 305-7528
                </p>
                <p>
                    <i className='bx bxs-envelope'></i>
                    Email: info@soapmat.com
                </p>
            </div>
            <div>
                <div className="copyright">
                <Link to="/privacy">Privacy</Link>
                <Link to="/payments">Payments</Link>
                <Link to="/contact">Site Map</Link>
                <Link to="/about/faq">FAQ's</Link>
                </div>
                <p>Copyright © 2023 SOAP MAT, LLC | All Rights Reserved</p>
            </div>
            <div>
                <img src={require('../images/hhsa.png')} className="hhsa" alt="..." />
            </div>
    </footer>
    )
}


export default Footer;