import './App.css';
import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import {AboutSoap, OurStaff, FAQ, Interview } from './components/About';
import { Route, Routes } from 'react-router-dom';
import Contact from './components/Contact';
import Resources from './components/Resources';
import Payments from './components/Payments';
import Privacy from './components/Privacy';
import { SubstanceUse, ChemDependency } from './components/EdMaterials';
import { Programs, Counseling, Medications, Perinatal, Youth} from './components/Services';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import RenewalBanner from './components/RenewalBanner';


function App() {
  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <RenewalBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about/soap-mat" element={<AboutSoap />} />
        <Route path="/about/our-staff" element={<OurStaff />} />
        <Route path="/about/faq" element={<FAQ />} />
        <Route path="/about/interview" element={<Interview />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/privacy" element={<Privacy /> } />
        <Route path="/payments" element={<Payments /> } />
        <Route path="/educational-materials/substance-use" element={<SubstanceUse /> } />
        <Route path="/educational-materials/chemical-dependency" element={<ChemDependency /> } />
        <Route path="/services/programs" element={<Programs /> } />
        <Route path="/services/case-management-counseling" element={<Counseling /> } />
        <Route path="/services/medications" element={<Medications /> } />
        <Route path="/services/perinatal-health" element={<Perinatal /> } />
        <Route path="/services/youth" element={<Youth /> } />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;


