import React, { useEffect, useRef, useState } from "react";
import { medicationsArray } from "./data.js";
import { Link } from 'react-router-dom';

export default function Medications() {
  const medDescRef = useRef(null);
  const [selectedMed, setSelectedMed] = useState(medicationsArray[0]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  function handleClick(e) {
    if (e.target.id) {
      const selectedMed = medicationsArray.find((med) => med.name === e.target.id);
      setSelectedMed(selectedMed);
    }
  }  
  
  function renderMedDescription() {
    if (selectedMed && selectedMed.name === "Upcoming") {
      const upcomingMed = medicationsArray.find((med) => med.name === "Upcoming");
      const upcomingMeds = upcomingMed.upcomingMeds;
      const descriptions = upcomingMed.descriptions;
  
      return (
        <div className="upcoming-container">
          <h1 className="purple bold">Coming soon to our facility</h1>
          <ul className="up-list">
            {upcomingMeds.map((med, index) => (
              <li className="square" key={index}>
                {med}: {descriptions[index]}
                <br />
                <Link className="read-more-btn" to="/services/medications">Read More</Link>
                <br></br>
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div>
          <h1 className="purple bold">{selectedMed.name}</h1>
          <ul className="meds-list-home">
            {selectedMed.description.map((item, index) => (
              <li className="square" key={index}>{item}</li>
            ))}
          </ul>
          <Link className="read-more-btn" to="/services/medications">Read More</Link>
        </div>
      );
    }
  }
  
  return (
    <div className="med-btns">
      <div className="meds">
        {medicationsArray.map((med) => (
          <button key={med.name} id={med.name} className={selectedMed && selectedMed.name === med.name ? "selected-button" : ""}>
            {med.name}
          </button>
        ))}
      </div>
      <div className="med-exp" style={{ textAlign: "justify" }}>
        <div ref={medDescRef} className="med-desc">
        <img src={require('../images/pill-img.png')} className="pill-img" alt="..."/>
          {selectedMed ? renderMedDescription() : null}
        </div>
      </div>
    </div>
  );
}
