import React from "react";


export default function QuickContact() {
    return (
        <div className="quick-contact box-shadow">
            <h2 className='purple bold' style={{textAlign: 'center'}}>CONTACT US</h2>
            <div style={{textAlign: 'center'}} className="qc-info bold">
                <p>3230 Waring Court | Suite A, Oceanside, CA 92056</p>
                <p>Phone: (760) 305-7528</p>
                <p>Email: info@soapmat.com</p>
            </div>
            <h2 className='purple bold' style={{textAlign: 'center'}}>QUICK CONTACT</h2>
            <form action="mailto:info@soapmat.com" encType="text/plain" className='form2'>
                <div className="form-group">
                    <label htmlFor="name">Your Name (required):</label>
                    <input type="text" name="name" id="name" className="form-control" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Your Email (required):</label>
                    <input type="email" name="email" id="email" className="form-control" required />
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject:</label>
                    <input type="text" name="subject" id="subject" className="form-control" required />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Your Message:</label>
                    <textarea name="message" id="message" rows="5" className="form-control" required></textarea>
                </div>
                <button type="submit" className="btn form2-btn">Submit</button>
            </form>
            <img src={require('../images/logo2.png')} className="qc-img" alt="..." />
      </div>
    )
}