import React from "react";
import QuickContact from "./QuickContact";

function Privacy() {
    return (
        <div>
            <div className='banner'>Privacy Policy</div>
            <div className="privacy">
            <div className="q-contact">
            <div className="priv-grid">
            <div className="priv-info box-shadow">
                <h4 style={{ textAlign: 'left' }} className="purple">Our Privacy Policy</h4>
                <p>At SOAP MAT, LLC, we are committed to protecting the privacy and confidentiality of our patients. This privacy policy outlines the standards and procedures we follow to ensure compliance with all current privacy regulations, including those specific to opiate treatment facilities and youth privacy standards. Please read this policy carefully to understand how we handle your personal information.</p>
                <h4 style={{ textAlign: 'left' }} className="purple">Confidentiality of Alcohol and Drug Abuse Patient Records</h4>

                <p>The confidentiality of alcohol and drug abuse patient records maintained by this program is protected by Federal Law and Regulations, as stated in 42 CFR, Chapter 1, Part 2. As such, SOAP MAT, LLC may not disclose any information that identifies a patient as an alcohol or drug abuser, except in the following circumstances:</p>

                <ul>
                    <li className="square">Patient Consent: SOAP MAT, LLC will not disclose any patient information unless the patient provides written consent for such disclosure.</li>

                    <li className="square">Court Order: In cases where a court order is issued, SOAP MAT, LLC may be required to disclose patient information as mandated by the order.</li>

                    <li className="square">Medical Emergency or Program Operations: SOAP MAT, LLC may disclose patient information to medical personnel in a medical emergency when necessary for the patient's treatment. Additionally, designated and qualified staff may access patient information for research, audit, or program evaluation purposes. Such staff members are bound by strict confidentiality requirements.</li>
                </ul>
                <h4 style={{ textAlign: 'left' }} className="purple">Reporting Violations</h4>
                <p>Any suspected violations of Federal Law and Regulations by SOAP MAT, LLC regarding patient record confidentiality will be treated seriously. Suspected violations may be reported to the appropriate authorities in accordance with Federal Regulations.</p>
                <h4 style={{ textAlign: 'left' }} className="purple">Exceptions to Confidentiality</h4>
                <p>Crimes Committed by a Patient: Please note that Federal Law and Regulations do not protect information regarding any crime committed by a patient at the program or against any person who works for the program. If a crime is committed or a threat to commit such a crime is made, SOAP MAT, LLC may disclose relevant information as required by law enforcement agencies.</p>
                <p>Suspected Child Abuse/Neglect: Federal Laws and Regulations do not protect information about suspected child abuse/neglect from being reported to appropriate State and Local Authorities under State law. SOAP MAT, LLC is obligated to report any such suspicions in accordance with the law.</p>
                <h4 style={{ textAlign: 'left' }} className="purple">Youth Privacy Standards</h4>
                <p>In addition to the above guidelines, SOAP MAT, LLC acknowledges the need to provide enhanced privacy protections for youth accessing our services. We take special care to ensure that any information related to minors is handled with utmost sensitivity and in compliance with applicable laws and regulations. We seek parental consent when required, and we employ strict controls to safeguard the privacy of youth in our care.</p>
                                <h4 style={{ textAlign: 'left' }} className="purple">Security Measures</h4>
                <p>SOAP MAT, LLC maintains reasonable security measures to protect the confidentiality and integrity of patient information. We utilize industry-standard physical, technical, and administrative safeguards to prevent unauthorized access, disclosure, or alteration of personal information.</p>
                <div>
                    <h4 style={{ textAlign: 'left' }} className="purple">Contact Information</h4>
                    <p>If you have any questions or concerns regarding our privacy practices, or if you wish to exercise your rights under applicable privacy laws, please contact us at:</p>
                    <div className="c-info">
                        <p>SOAP MAT, LLC  </p>
                        <p>3230 Waring Court, Suite A</p>
                        <p>Oceanside, CA 92056</p>
                        <p><i className='bx bxs-phone'></i> Phone: (760) 305-7528</p>
                        <p><i className='bx bxs-envelope'></i>Email: info@soapmat.com</p>
                </div>
            </div>

            <div>
                <h4 style={{ textAlign: 'left' }} className="purple">Changes to the Privacy Policy:</h4>
                <p>SOAP MAT, LLC reserves the right to update or modify this privacy policy at any time. Any changes will be posted on our website or communicated directly to our patients, as required by law.By continuing to engage with our services, you acknowledge that you have read, understood, and agreed to the terms outlined in this privacy policy.
                </p>
            </div>
            </div>
            </div>
                <QuickContact />
            </div>
        </div>
    </div>
    )
}

export default Privacy;