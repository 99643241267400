import React from 'react';
import Medications from './Medications';
import { Link } from 'react-router-dom';


export default function Home() {
    return (
    <div>
    {/* Carousel */}
        <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={require('../images/2carimg1.png')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
              <p className='soap-font'>Opioid Treatment in San Diego, CA</p>
              <h3>SOAP MAT, LLC</h3>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/2carimg2.png')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
              <h1>Perinatal Services</h1>
              <button className="car-btn">
                <Link to="/services/perinatal-health" className='sec1-btn'>CLICK HERE</Link>
              </button>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/2carimg3.png')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
              <h1>Youth Services</h1>
              <button className="car-btn">
                <Link to="/services/youth" className='sec1-btn'>CLICK HERE</Link>
              </button>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/2carimg4.png')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
              <h1>Our Medications</h1>
              <button className="car-btn">
                <Link to="/services/medications" className='sec1-btn'>CLICK HERE</Link>
              </button>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>      

    {/* Section1 */}
        <section className="sect1" >
        <div className="sec1-item s1-color1">
          <h2 className="title purple">ABOUT US</h2>
          <p className="s1-desc1">
            We provide counseling, case management, social work, referrals to community resources, educational programs, work eligibility, vocational rehabilitation and nutritional counseling.
          </p>
          <div className="btn-cont">
            <button className="sec1-btn">
              <Link to="/about/soap-mat" className='sec1-btn'>LEARN MORE</Link>
            </button>
          </div>
        </div>
        <div className="sec1-item s1-color2">
          <h2 className="title purple">OUR PROGRAMS</h2>
          <p className="s1-desc2">
            Medication-Assisted Treatment (MAT) is the use of medications, in combination with counseling and therapeutic interventions, to provide a patient focused approach to the treatment of substance use disorders.
          </p>
          <div className="btn-cont">
            <button className="sec1-btn">
              <Link to="/services/programs" className='sec1-btn'>LEARN MORE</Link>
            </button>
          </div>
        </div>
        <div className="sec1-item s1-color1">
          <h2 className="title purple">OUR FACILITIES</h2>
          <p className="s1-desc3">
            SOAP MAT is located in Oceanside, California at the Tri City Medical Park, adjacent to Tri City Hospital, servicing both coastal and inland communities.
          </p>
          <div className="btn-cont">
            <button className="sec1-btn">
              <Link to="contact" className='sec1-btn'>LEARN MORE</Link>
            </button>
          </div>
        </div>
      </section>

    {/* Section2 */}
<section className="sect2" style={{ textAlign: 'justify' }}>
  <h1 className="c-heading purple bold" style={{ textAlign: 'center'}}>Our Treatment Program Focuses on Long Term Results</h1>
  <div className='sect2-info'>
    <p className="sect2-item">
    SOAP MAT, LLC functions as an outpatient clinic specifically designed to address the challenges faced by individuals grappling with the misuse of opioid drugs, encompassing prescription pain medications, heroin, fentanyl, and forthcoming opioids. The widespread impact of prescription drug and opioid misuse on millions of Americans each year necessitates a collective response. We want to assure you that you, or your loved one, are not alone in contending with this debilitating disease.
    </p>
    <p className="sect2-item">
    The detrimental effects of substance use disorders extend beyond the well-being and contentment of those directly affected by these types of disorders. They permeate throughout the concerned network of friends, family members, and loved ones. Acknowledged as chronic diseases by esteemed organizations such as the World Health Organization, American Medical Association, National Institute of Drug Addiction, and the Center for Substance Abuse Treatment, substance use disorders have been substantiated to be effectively managed through a combination of appropriate medication and counseling.
    </p>
    <p className='sect2-item'>
    The opioid use disorder treatment program offered by SOAP MAT centers around medication-assisted treatment (MAT), skillfully integrating pharmacologic interventions with substance misuse counseling to empower patients in achieving their objectives.
    </p>
    <p className="sect2-item">
    Our primary objective at SOAP MAT is to augment and promote the quality of life for all patients we serve, adhering to a harm reduction model. Our foremost priority is the individual stabilization of each patient, achieved through a comprehensive approach encompassing medically supervised treatment and psychosocial rehabilitation services within a secure and clinically supportive environment. 
    </p>
    <p className="sect2-item">
    To address such a complex and multifaceted issue, SOAP MAT employs a comprehensive methodology encompassing therapeutic interventions and medication, emphasizing a holistic and patient-centered approach to patient care. Our highly skilled multidisciplinary team and the integrated scope of our treatment services enable patients to effect positive transformations in their lives. Most significantly, SOAP MAT endeavors to foster an atmosphere characterized by dignity and respect, ensuring that patients feel embraced and comfortable as they endeavor to achieve optimal recovery and personal growth.
    </p>
  </div>
</section>

  {/* Medications */}

<section>
    <div className='home-med'>
      <h2 className="bold title purple">Our comprehensive approach includes Medication-Assisted Treatment (MAT) services</h2>
        <div className="med-sect">
          <div className="med-list box-shadow">
            <div className="med-btns">
              <div className='meds'></div>
            </div>
            <div className="med-exp">
              <div className="med-desc">
                <Medications />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


{/* Direct to FAQ section */}

<div className='home-faq'>
  <h2 className='bold hf-heading' style={{textAlign: 'left'}}>Have questions about our treatment program? We're here to help.</h2>
  <Link to="/about/faq" className='h-faq'>GET ANSWERS NOW</Link>
</div>
        {/* Testimonials */}
      <div id="carousel2" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carousel2" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carousel2" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carousel2" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carousel2" data-bs-slide-to="3" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carousel2" data-bs-slide-to="4" aria-label="Slide 5"></button>
          <button type="button" data-bs-target="#carousel2" data-bs-slide-to="5" aria-label="Slide 6"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={require('../images/sand.avif')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
              <h4 className='testimonials bold'>Testimonials</h4>
              <h5>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
              </h5>
              <p className="testimonial">I have always been treated with care and compassion and respect from this clinic. I have gone to other clinics over the past years and I have never been treated as well as I have from this clinic, there is no comparison actually! This clinic from the counselors to the nurses to the front desk staff they all treat the clients with respect and dignity something that no other clinic has ever done and I am truly grateful!! Thank you Soap Mat for helping me get my life back on track I am coming up on one year clean and sober and I could not have done it without Soap Mat!</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/sand.avif')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
            <h4 className='testimonials bold'>Testimonials</h4>
              <h5>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
              </h5>
              <p className="testimonial">Best clinic in San Diego County period! I drive 43 miles to visit this clinic. 4 different clinics I have attended and by far this one is the most efficient, friendliest (they actually care about you as a human and will help you if needed instead of trying to shove you out and not caring) and well maintained. If you need help and are ready to get clean, go here, even if it's just a little out of the way as long as you can drive it will be worth it and with how friendly the staff and nurses are, it could save your life.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/sand.avif')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
            <h4 className='testimonials bold'>Testimonials</h4>
              <h5>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
              </h5>
              <p className="testimonial">I have been to my share of clinics over the years. This clinic is number one and beyond compare. SOAP MAT is located in a proper medical park next to tri city hospital. The staff are happy and good fun people, the kindness and patience they give you is inimitable. I have been on methadone maintenance for about 13 yrs. It works for me, I have not used since I started treatment. Laura Rossi, Phd CEO/director of SOAP MAT is the best there is, there is no other way to put it "THE BEST". Thank you SOAP MAT staff for caring, kindness and patience.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/sand.avif')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
            <h4 className='testimonials bold'>Testimonials</h4>
              <h5>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
              </h5>
              <p className="testimonial">The best people ever. Staff is amazing. This organization is truly helpful in times of difficulty and need. The service they provide is professional and kind. They are the best in the business.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/sand.avif')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
            <h4 className='testimonials bold'>Testimonials</h4>
              <h5>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
              </h5>
              <p className="testimonial">Amazing nurses, amazing doctor! Staff is friendly and non-judgmental and passionate about their work. I highly recommend it for anyone who wants to better their life.</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require('../images/sand.avif')} className="d-block w-100 car-img" alt="..." />
            <div className="carousel-caption d-flex align-items-center justify-content-center car-cap">
            <h4 className='testimonials bold'>Testimonials</h4>
              <h5>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
                <i className='bx bxs-star'></i>
              </h5>
              <p className="testimonial">I’ve been to Mission, SOAP, and now Capalina, and without question, SOAP is the best. I would have never switched if it weren’t for insurance issues. In fact, that’s SOAP’s one and only downfall: they don’t accept any insurance besides Medi-Cal. Otherwise, though, they’re wonderful, caring, organized, and helpful - and a few dozen other positive adjectives, too. Contrast this with, say, Capalina who treats you like a prisoner, not a patient. When people say Methadone is liquid handcuffs, they’re talking about places like Capalina, not SOAP. SOAP helps you achieve your goals and never feels oppressive or ashamed - I wish I could say that much for Capalina. I’ll be switching back to SOAP as soon as possible.</p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carousel2" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carousel2" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

    {/* Contact */}

      <div className="contact">
        <div>
          <h1 className='purple bold'>Start your recovery journey today.</h1>
        </div>

        <form enctype="text/plain" className='form1' action="mailto:info@soapmat.com">
          <div className="form-group">
            <input type="text" name="name" id="name" placeholder='Enter Your Full Name:' className="form-control" required />
          </div>
          <div className="form-group">
            <input type="email" name="email" id="email" placeholder='Enter Your Email:' className="form-control" required />
          </div>
          <div className="form-group">
              <input type="text" name="subject" id="subject" placeholder='Enter Your Subject:' className="form-control" required />
          </div>
          <div className="form-group">
            <textarea name="message" id="message" placeholder='Enter Your Message:' rows="4" className="form-control" required></textarea>
          </div>
          <button type="submit" className="btn btn-primary car-btn">Submit</button>
        </form>
      </div>
    </div>
    )
}



