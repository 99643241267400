import React from "react";
import QuickContact from './QuickContact';

function SubstanceUse() {
  return (
    <div>
      <div className='banner'>SUBSTANCE USE DISORDERS</div>
      <div className="substance-use">
        <div className="q-contact">
          <div className="sub-cont">
            <div className="sub-info box-shadow">
              <div className="sg-item">
                <div className="s-item1">
                  <h1 className="bold purple">How do Substance Use Disorders begin?</h1>
                  <div className="sg-img">
                    <img src={require('../images/sub-use.png')} className="sub-img" alt="..." />
                  </div>
                  <p>Substance use disorders develop when the brain's reward circuitry associates substance use with pleasure or reward, leading to repeated misuse. This is seen as particularly strong in those struggling with opioid misuse. Prolonged opioid use alters the functioning of nerve cells, creating a dependence on the presence of opioids. When opioids are abruptly stopped, withdrawal symptoms occur, which can vary in severity depending on factors like age, usage amount, and duration. These symptoms include restlessness, muscle and bone pain, insomnia, diarrhea, vomiting, and intense drug cravings. These symptoms and rewiring of the brain’s reward system often lead to individuals engaging in high-risk and illicit behaviors that continue to exacerbate their struggles physically, mentally and socially.</p>
                  <p>Substance use disorders can also be influenced by psychological and environmental factors. Mental health conditions like depression, anxiety, or trauma can contribute to the development of substance use disorders, as individuals may turn to substances as a way to self-medicate or escape from distressing emotions. Environmental factors, such as a family history of substance misuse, dysfunctional family dynamics, or exposure to high-risk social environments, can also increase the likelihood of developing a substance use disorder.</p>

                  <h1 className="purple bold">Overview of Substance Use Disorders</h1>
                  <p>Substance use disorders are complex conditions that arise when a person engages in activities or uses substances that strongly affect the brain, leading to three main manifestations: a strong desire for the substance, loss of control over its use, and persistent involvement despite adverse consequences. Substance use disorders are chronic brain disorders characterized by compulsive and harmful high-risk behavior. It causes dysfunctional learning, memory impairment, and maladaptive behavioral patterns, including impaired decision-making and loss of control.</p>

                  <p>It's important to note that being diagnosed with a substance use disorder is considered a type of disability, similar to other physical or cognitive impairments. However, with appropriate treatment, many individuals can maintain a stable and healthy lifestyle devoid of the symptoms of their substance use disorder diagnosis. Additionally, these individuals are protected under the Americans with Disabilities Act (ADA).</p>

                  <h1 className="purple bold">The Impact of Substance Use Disorders</h1>
                  <p>The impact of suffering from any form of substance use disorder, particularly opioid use disorder, is substantial in many biopsychosocial aspects of life. Not only do these individuals experience physical and psychological maladies, social and economic issues often arise as a byproduct of the high-risk behaviors and other factors involved in substance misuse. As cited in a publication from the National Library of Medicine; “High-risk behaviors are defined as acts that increase the risk of disease or injury, which can subsequently lead to disability, death, or social problems”.</p>
                  <p>Outside of the individual, there is also a large impact on our country as a whole. The economic cost associated with these disorders continues to rise at a dramatic rate yearly. Opioid Use Disorder in particular is currently significantly burdening the United States economy, leading to increased healthcare expenses, loss of productivity, crime rates, and incarceration. Illicit drug use alone contributes to billions of dollars in healthcare costs, productivity loss, and criminal justice expenses. As a main part of SOAP MAT’s mission, reducing these costs and expenses to the community as a whole is imperative.</p>
                </div>
              </div>
            </div>
          </div>
          <QuickContact />
        </div>
      </div>
    </div>
  );
}

function ChemDependency() {
  return (
    <div>
      <div className="banner">CHEMICAL DEPENDENCY</div>
      <div className="chem-dep">
        <div className="q-contact">
          <div className="chem-cont">
            <div>
              <div className="chem-info box-shadow">
                <h1 className="purple bold">Distinguishing between Chemical Dependency and Substance Use Disorders</h1>
                <h4 className="green" style={{textAlign: 'left'}}>It's crucial to distinguish substance use disorders from chemical dependency.</h4>
                <div className="c-flex">
                  <div>
                    <img src={require('../images/chem-dep.png')} className="chem-img" alt="..."/>
                    <div className="c-item">
                      <h3 className="purple bold" style={{textAlign: 'left'}}>Chemical Dependency: </h3>
                      <ul>
                        <li className="square">A normal physiological response to repeated substance use</li>
                        <li className="square">Do NOT involve high-risk behaviors</li>
                      </ul>
                      <h3 className="purple bold" style={{textAlign: 'left'}}>Substance Use Disorders: </h3>
                      <ul>
                        <li className="square">Develop when the brain's reward circuitry associates drug use with pleasure or reward, leading to repeated abuse</li>
                        <li className="square">Involve compulsive use despite life-threatening consequences.</li>
                      </ul>
                      <div className="c-item">
                        <h1 className="purple bold">What are opiates and how do they work?</h1>
                        <p>Opiates are a group of substances used for pain relief, but they also have a high potential for misuse. They can be derived from the Asian poppy plant or synthesized. Opiates and synthetic opiates, collectively known as opioids, bind to opiate receptors in the body, mimicking the structure of natural neurotransmitters. Some examples of opioids are: Heroin, Fentanyl (including illicitly manufactured fentanyl), Carfentanil, Oxycodone, Hydrocodone, Morphine, Codeine. By targeting the brain's reward system and flooding it with dopamine, opioids produce euphoric effects.</p>
                      </div>
                      <div className="c-item">
                        <h1 className="purple bold">Dependency Explained</h1>
                        <p>It's essential to approach chemical dependency with compassion and understanding, recognizing it as a complex brain disorder that requires comprehensive support and treatment to help individuals recover and regain control of their lives. One of the key factors contributing to chemical dependency is tolerance. As individuals continue to use substances, their bodies gradually adapt to the substance, requiring higher doses to achieve the same initial response. This phenomenon can lead to a dangerous cycle of escalating consumption.</p>
                        <p>According to the Mayo Clinic, opioid tolerance refers to the phenomenon where a person requires higher doses of opioids over time to achieve the same pain-relieving effect. Tolerance occurs because the body adjusts to the presence of opioids and becomes less responsive to their effects. This can lead to a diminished therapeutic response and the need for increasing doses to achieve pain relief. Opioid tolerance can develop with both prescription opioids and illicit opioids. Opioid dependence, on the other hand, is a condition characterized by withdrawal symptoms when opioid use is stopped or reduced. Dependence occurs as the body becomes adapted to the presence of opioids and relies on them to function normally. When opioid intake is abruptly decreased or discontinued, the body reacts with withdrawal symptoms such as anxiety, insomnia, muscle aches, nausea, and irritability.</p>
                        <p>
                          <span className="bold" >
                            Source: Mayo Clinic. (2021). Opioid tolerance and dependence. </span><span> Retrieved from </span>
                          <a className="purple" href="https://www.mayoclinic.org/diseases-conditions/prescription-drug-abuse/in-depth/opioid-tolerance-dependence/art-20388330">https://www.mayoclinic.org/diseases-conditions/prescription-drug-abuse/in-depth/opioid-tolerance-dependence/art-20388330</a>
                        </p>
                        <p>Moreover, dependence occurs when the neurons in the brain adapt to the substance's presence and can only function normally in its presence. This state of dependence can manifest in various ways, both physically and psychologically. When the substance is suddenly discontinued, individuals may experience withdrawal syndrome, which can range from mild to life-threatening. It is important to recognize that dependency is a normal physiological response to repeated substance use. However, it is equally crucial to approach chemical dependency with compassion and understanding. Chemical dependency is not a moral failing or a lack of willpower; it is a brain disorder that requires comprehensive support and treatment.</p>
                        <p>Here at SOAP MAT, we emphasize the importance of providing a supportive environment for individuals struggling with chemical dependency. We promote a holistic approach that combines medical intervention, counseling, and behavioral therapies. By addressing the underlying factors contributing to chemical dependency, we empower individuals to regain control of their lives and work towards long-lasting recovery. We believe in the power of education and awareness to combat the stigma surrounding chemical dependency and substance misuse. By fostering understanding and empathy, we can create a more compassionate society that supports individuals on their journey towards recovery. Whether you are seeking information, resources, or guidance, our website is here to assist you in navigating the complexities of chemical dependency and finding the help you need. Remember, recovery is possible, and you are not alone. Together, we can make a difference and help individuals overcome chemical dependency, reclaim their lives, and build a brighter future.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <QuickContact />
        </div>
      </div>
    </div>
  );
}

export default ChemDependency;

export { SubstanceUse, ChemDependency };
